import React from 'react'
import { NAVAR } from '../../navbar/navbar';
import { FOOTER } from '../../footer/footer';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { FORM_JOB_OPP } from './form';

export const DESCRIPTION_JOB = () => {
  
  const location = useLocation();
  const data = location.state || {};
  console.log(data);

  return (
    <>
    <NAVAR></NAVAR>
    <div className='content-descrip-jobitem'>
        <div className='grid-descrip-jobs'>
            <div className='desc-job1'>
                <p><span>VACANTE:</span> {data?.title}</p>
                <p><span>DEPARTAMENTO:</span> {data?.department}</p>
                <div className='jobs-desc-item'>
                    <p><span>DESCRIPCIÓN:</span></p>
                    <p className='item-desc-info'>{data?.job_description}</p>
                </div>
                <div className='jobs-req-item'>
                    <p className='title-items-list'><span>REQUISITOS:</span></p>
                    <p className="list-items" dangerouslySetInnerHTML={{ __html: data?.requirements?.replace(/\n/g, '<br />') }}></p>
                </div>
                <div className='jobs-req-item'>
                    <p className='title-items-list'><span>BENEFICIOS:</span></p>
                    <p className="list-items" dangerouslySetInnerHTML={{ __html: data?.benefits?.replace(/\n/g, '<br />') }}></p>
                </div>
            </div>
            <div className='desc-job2'>
                <FORM_JOB_OPP data={data}></FORM_JOB_OPP>
            </div>
        </div>
    </div>
    <FOOTER></FOOTER>
    </>
    
  )
}
