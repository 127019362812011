import React from 'react';
import "./values.css";
import c1 from "../../images/c1.png";
import c2 from "../../images/c2.png";
import c3 from "../../images/c3.png";
import c4 from "../../images/c4.png";
import c5 from "../../images/c5.png";


export const VALUES = () => {
  return (
    <div className='values-container' id='values-container'>
        <div className='cap-white'></div>
        <div className='values-content'>
            <h2 className='titleh2'>VALORES</h2>
            <div className='grid-values'>
                <div className='vg1'>
                    <div className='images'>
                        <img src={c1} alt='c1'></img>
                    </div>
                    <div className='descripcion'>
                        <h2>Compromiso</h2>
                        <p>
                            Es la actitud positiva y verdadera que permita transformar las promesas en realidad 
                            con un mejoramiento constante de nuestras acciones aun en las circunstancias adversas.
                        </p>
                    </div>

                </div>
                <div className='vg2'>
                    <div className='images'>
                    <img src={c2} alt='c1'></img>
                    </div>
                    <div className='descripcion'>
                        <h2>Responsabilidad</h2>
                        <p>
                            Realizar nuestras actividades en tiempo y forma sin afectar los intereses de los demás 
                            siendo conscientes de nuestros actos y sus consecuencias.
                        </p>
                    </div>
                </div>
                <div className='vg3'>
                    <div className='images'>
                    <img src={c3} alt='c1'></img>
                    </div>
                    <div className='descripcion'>
                        <h2>Honestidad</h2>
                        <p>
                            Hablar y actuar con la verdad aun en situaciones contrarias.
                        </p>
                    </div>
                </div>
                <div className='vg4'>
                    <div className='images'>
                    <img src={c4} alt='c1'></img>
                    </div>
                    <div className='descripcion'>
                        <h2>Lealtad</h2>
                        <p>Hacer, crecer y cumplir correctamente nuestras actividades en todo momento.</p>
                    </div>
                </div>
                <div className='vg5'>
                    <div className='images'>
                    <img src={c5} alt='c1'></img>
                    </div>
                    <div className='descripcion'>
                        <h2>Ética</h2>
                        <p>Actuar y comprometerse correctamente de acuerdo a los principios y reglas 
                            para mantener una armonía y felicidad.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
