import React from 'react';
import "./slide.css";
import sello1 from "../../images/sello1.png";
import sello2 from "../../images/sello 2.png";
import sello3 from "../../images/sello3.png";
import slide from "../../images/ice-cream-1.png";
import { CustomDialog } from "react-st-modal";
import { ORDER } from '../../tools/order/order';
import TERRITORY from '../../tools/territory/territory';
import { FREZZ } from '../../tools/freez/frezz';

export const SLIDE = () => {

  const pedido = async() =>{
    const result = await CustomDialog(
      <ORDER/>,
      {
        className: "custom-modal",
        title: "Realizar Pedido",
        showCloseIcon: true,
        isCanClose:false,
      }
    );
  }

  const territory = async()=>{
    const terr = await CustomDialog(
      <TERRITORY/>,
      {
        className: "custom-modal",
        title: "Lista",
        showCloseIcon: true,
        isCanClose:false,
      }
    );
  }

  const center = async() =>{
    await CustomDialog(
      <FREZZ/>,
      {
        className: "custom-modal",
        title: "Reportar Congelador",
        showCloseIcon: true,
        isCanClose:false,
      }
    );
  }


  return (
    <div className='slide-container'>
        <div className='slide-content'>
            <div className='slide-section'>
                <div className='slide-titles'>
                  <h2 translate="no">FRICONGELADOS CITLALTEPETL</h2>
                  <p translate="no">
                  Nuestra empresa se dedica a establecer una presencia estratégica en mercados clave y a cultivar
                   relaciones sólidas y duraderas con nuestros clientes.</p>
                </div>
                <div className='slide-images'>
                  <img src={slide} alt='helado'></img>
                </div>
            </div>
        </div>
        
        <div className='slide-black-container'>
          <div className='cap-black2'></div>
            <div className='slide-black-grid'>
            
              <div className='sbg1'>
                <img src={sello1} alt='sello1'></img>
                <div className='sbgtext'>
                  <p className='title' translate="no">Distribución de Helados Holanda</p>
                  <p className='text' translate="no">Ofrecemos una amplia gama de helados Holanda, garantizando la entrega puntual 
                    y en perfectas condiciones de calidad. 
                  </p>
                  <button onClick={()=>{pedido()}} translate="no">Realizar Pedido</button>
               </div>
               </div>
              <div className='sbg2'>
                <img src={sello2} alt='sello1'></img>
                <div className='sbgtext'>
                  <p className='title' translate="no">Atención al Cliente</p>
                  <p className='text' translate="no">¿Tiene algún problema con su congelador?. Reportalo y nosotros nos encargamos. 
                  </p>
                  <button translate="no" onClick={()=>center()}>Reportar</button>
                </div>
                </div>
              <div className='sbg3'>
                <img src={sello3} alt='sello1'></img>
                <div className='sbgtext'>
                  <p className='title' translate="no">Territorio</p>
                  <p className='text' translate="no">Conoce todos los municipios en que ofrecemos nuestro servicio.</p>
                  <button onClick={()=>territory()}>Ver Lista</button>
                </div>
              </div>

            </div>
        </div>
    </div>
  )
}
