import React, { useEffect, useState } from 'react';
import { NAVAR } from '../navbar/navbar';
import { FOOTER } from '../footer/footer';
import "./jobs.css";
import { get_jobs } from '../../services/services';
import img from "../../images/helado.gif";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Auth from '@aws-amplify/auth';
import AWS from 'aws-sdk';
import awsconfig from '../../aws-exports';
import {Amplify} from 'aws-amplify';

Amplify.configure(awsconfig);
Auth.configure({
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,
    region: awsconfig.aws_cognito_region
}); 

export const LABOR_EXCHANGE = () => {
    const [data, setdata] = useState([]);
    const [load, setload] = useState(false);
    const history = useHistory();
    
    const API_ENDPOINT = 'https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/web';

    const configureAWSCredentials = (credentials) => {
        AWS.config.update({
            region: 'us-east-1',
            credentials: new AWS.Credentials({
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            }),
        });
    };

    
    const handleLoginSuccess = async (googleToken) => {
        try {
            console.log('Token de Google:', googleToken);       
            sessionStorage.setItem('googleToken', googleToken);

            const credentials = await Auth.federatedSignIn(
                'accounts.google.com',
                { token: googleToken},
                "Usuario Google"
            );
            configureAWSCredentials(credentials);
            await invokeAPIWithSignature('GET', {});
        } catch (error) {
            setload(false);
            console.error("Error al autenticar en AWS Cognito:", error);

        }
    };

    const invokeAPIWithSignature = async (method = 'GET', body = null) => {
        try {
            const url = `${API_ENDPOINT}`;
    
            const request = new AWS.HttpRequest(url, AWS.config.region);
            request.method = method;
            request.headers['host'] = new URL(API_ENDPOINT).host;
            request.headers['Content-Type'] = 'application/json';
            
            const signer = new AWS.Signers.V4(request, 'execute-api');
            signer.addAuthorization(AWS.config.credentials, new Date());

            const response = await fetch(url, {
                method: request.method,
                headers: request.headers,
                body: request.body ? request.body : null,
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log("Datos recibidos:", data);
                setdata(data);
                setload(false);
            } else {
                setload(false);
                console.error("Error en la respuesta de la API:", response.status, response.statusText);
            }
        } catch (error) {
            setload(false);
            console.error("Error al invocar la API:", error);
        }
    };
    

    const handleCallbackResponse = (response) => {
        const token = response.credential;
        setload(true);
        handleLoginSuccess(token)
            .then(() => {
                console.log("Autenticación exitosa");
            })
            .catch((error) => {
                console.error("Error en la autenticación con AWS", error);
                setload(false);
                sessionStorage.setItem('googleToken', "");
            });
    };

    const checkSession = async () => {
        setload(true);
        const storedToken = sessionStorage.getItem('googleToken'); 
        if (storedToken) {
            try {
                await handleLoginSuccess(storedToken);
                setload(false);
            } catch (error) {
                console.error("Token inválido o expirado:", error);
                sessionStorage.removeItem('googleToken');
                setload(false);
            }
        }else{
            setload(false);
        }
    };


    useEffect(() => {
        checkSession();
        
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: awsconfig.google_client_id, 
                callback: handleCallbackResponse,
            });
            window.google.accounts.id.renderButton(
                document.getElementById("btn-signin-google"),
                { theme: "outline", size: "large" }
            );
        } else {
            console.log('Error al cargar API de Google');
        }
    }, []);

    const build = (item) => {
        history.push({
            pathname: "/labor_exchange/description",
            state: item,
        });
    };
    return (
        <>
            <div>
                <NAVAR></NAVAR>
                <div className='slide-job'>
                    <div className='capa'></div>
                    <h2>BOLSA DE TRABAJO</h2>
                </div>    
                <div id='btn-signin-google'></div>
            
                {load === true ? (
                    <div className='loading-carts-jobs'>
                        <img src={img} alt=''></img>
                        <h2>Cargando Vacantes ...</h2>
                    </div>
                ) : (
                    <div className='grid-carts-jobs'>
                        {data.length === 0 ?
                            
                            sessionStorage.getItem('tokengoogle') === "" || sessionStorage.getItem('tokengoogle') === null 
                            || sessionStorage.getItem('tokengoogle')=== undefined ?
                        (
                            <div className='thanks-vacants'>
                                <h2>Porfavor usuario inicia sesión con tu cuenta de google para poder realizar las consultas de vacantes</h2>
                            </div>
                        ):
                        (
                            <div className='thanks-vacants'>
                                <h2>Gracias por tu interés, pero actualmente no hay vacantes disponibles.</h2>
                            </div>
                        ) : (
                            data.map((item, index) => (
                                <div className='cart-item' key={index}>
                                    <div className='cart-item-cap'></div>
                                    <div className='desc-item-cart'>
                                        <h2>{item?.title}</h2>
                                        <p className='item-depart'>{item?.department}</p>
                                        <p className='item-type'>{item?.employment_type}</p>
                                        <div className='descrip'>
                                            <p>{item?.job_description}</p>
                                        </div>
                                    </div>
                                    <div className='button-post'>
                                        <button onClick={() => build(item)}>MÁS INFORMACIÓN</button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
                <FOOTER></FOOTER>
            </div>
        </>
    );
};
