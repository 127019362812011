import React from 'react';
import "./section.css";
import img1 from "../../images/ice-cream-1.png";

export const SECTION = () => {
  return (
    <div className='section-container' id='section-container'>
        <div className='section-content'>
            <div className='section-info'>
                <p className='text-gray'>Sobre Nosotros</p>
                <p className='text-title'>FRICONGELADOS</p>
                <p className='text-about'>
                    Fricongelados Citlaltepetl S.A de C.V. nace en la ciudad de Orizaba Veracruz, 
                    en Agosto de 1999, concesión que nos brinda Unilever de México.
                </p>
                <div className='section-grid'>
                    <img 
                        src={"https://quierovenderhelados.com/img/paletas-sabias-que.png"} 
                    alt='image-cream'></img>
                </div>
            </div>
        </div>
    </div>
  )
}
