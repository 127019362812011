import React from 'react';
import logo from "../../images/Fricongelados-01.png";
import "./footer.css";
import { TERMS } from '../../tools/terms/terms';
import { CustomDialog } from "react-st-modal";

export const FOOTER = () => {

    const terms = async() =>{
        const result = await CustomDialog(
            <TERMS/>,
            {
              className: "custom-modal",
              title: "   ",
              showCloseIcon: true,
              isCanClose:false,
            }
          );    
    }

  return (
    <>
    <div className='footer-container' id='footer-container'>
        <div className='cap-black'></div>
        <div className='footer-content'>
            <div className='footer-grid'>
                <div className='ffg1'>
                    <img src={logo} alt=''></img>
                </div>
                <div className='ffg2'>
                    <div className=''>
                        <h2>CONTACTO</h2>
                        <div className='line'></div>
                        <p>Orizaba, Veracruz.</p>
                        <p>atencion.clientes@fricongelados.com</p>
                    </div>
                </div>
                <div className='ffg3'>
                    <div className=''>
                        <h2>SERVICIOS</h2>
                        <div className='line'></div>
                        <p>Distribución de Helados Holanda</p>
                        <p>Atención al Cliente</p>
                        <p>Logística y Transporte</p>
                    </div>
                </div>
                <div className='ffg4'>
                    <div className=''>
                        <h2>NOSOTROS</h2>
                        <div className='line'></div>
                        <p>Misión</p>
                        <p>Visión</p>
                        <p>Valores</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='footer-page'>
        <p><span>Fricongelados</span> © 2024 Todos los Derechos Reservados</p>
        <p className='terms' onClick={()=>terms()}>Aviso de Privacidad</p>
    </div>
    </>
    
  )
}
