import React, { useState } from 'react';
import "./order.css";
import { post_order } from '../../services/services';

export const ORDER = () => {

  const [name, setname]=useState("");
  const [tel, settel]=useState("");
  const [email,setemail]=useState("");
  const [prod, setprod]=useState("");
  
  const order = async() =>{

    if(email === "" || tel === "" || name === "" || prod === ""){
        alert("Faltan valores en el formulario");
        return null;
    }

    var data ={
        email: email,
        id_freezer: "",
        message: "",
        name: name,
        order: prod,
        phone: tel,
        type: "pedir_producto"
    }
    try{
        const d = await post_order(data);
        alert("Correo Enviado");
        setemail("");
        setname("");
        setprod("");
        settel("");
    }catch(err){
        console.log(err);
    }
  }


  return (
    <div className='order-container'>
        <div className='order-content'>
            <div className='forms'>
                <h2>Realizar Pedido</h2>
                <div className='forms-inputs'>
                    <forms>
                        <input 
                            placeholder='Nombre'
                            value={name}
                            onChange={(e)=>setname(e.target.value)}
                        ></input>
                        <input 
                            placeholder='Teléfono'
                            onChange={(e)=>settel(e.target.value)}
                        ></input>
                        <input 
                            placeholder='Correo Electrónico'
                            onChange={(e)=>setemail(e.target.value)}
                        ></input>
                        <textarea 
                            placeholder='Escribe el nombre y la cantidad de producto'
                            onChange={(e)=>setprod(e.target.value)}
                        ></textarea>
                    </forms>
                 </div>
                <button onClick={()=>order()}>PEDIR</button>
                
            </div>
        </div>
    </div>
  )
}
