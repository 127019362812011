import React from 'react';
import "./terms.css";

export const TERMS = () => {
  return (
    <div className='terms-content'>
        <h2>Aviso de Privacidad simplificado</h2>
        <p>Con fundamento en lo dispuesto por los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares hacemos de su conocimiento que FRICONGELADOS CITLALTEPETL, S.A. DE C.V., “FRICONGELADOS”, sociedad constituida conforme a las leyes mexicanas, con domicilio fiscal en: Calle Belisario Domínguez, número 588, Colonia Barrio Nuevo, C.P. 94340, Orizaba, Veracruz de Ignacio de la Llave, es la persona responsable del tratamiento de sus datos personales para las siguientes finalidades:</p>
        <p>Para darlo de alta como cliente y/o proveedor de nuestros productos.</p>
        <p>Para crear un perfil del titular.</p>
        <p>Procesar sus solicitudes, y por lo tanto administrar la relación que quiere establecer con FRICONGELADOS o la ya establecida entre usted y la sociedad misma.</p>
        <p>Para las finalidades administrativas de todas formas relacionadas con el desempeño de las obligaciones contractuales relativas, para el desempeño de las obligaciones de ley, como por ejemplo, las de tipo contable o fiscal.</p>
        <p>Para fines estadísticos.</p>
        <p>Para dar seguimiento a las solicitudes que usted nos haya planteado, cualquiera que sea el motivo, tales como información de productos, cotizaciones, y otros análogos.</p>
        <p>Transferir sus datos, en caso de que se requiera a nuestro principal Proveedor UNILEVER .</p>
        <p>Garantizar un tratamiento correcto y adecuado.</p>
        
    </div>
  )
}
