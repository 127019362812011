import React, { useEffect } from 'react';
import "./territory.css";

const initMap = () => {
  const terMap = new window.google.maps.Map(document.getElementById('terMap'), {
    zoom: 8,
    center: { lat: 18.8504744, lng: -97.10363960000001 },
  });
  const polygonCoords = [
    { lat: 18.098389, lng: -97.061086 },
    { lat: 18.1340995, lng: -97.0669737 },
    { lat: 18.234239, lng: -97.083384 },
    { lat: 18.274089, lng: -97.089157 },
    { lat: 18.295751, lng: -97.060307 },
    { lat: 18.333566, lng: -97.0193 },
    { lat: 18.38674, lng: -97.04194 },
    { lat: 18.486877, lng: -97.127166 },
    { lat: 18.589497, lng: -97.241074 },
    { lat: 18.604275, lng: -97.267395 },
    { lat: 18.665911, lng: -97.358398 },
    { lat: 18.678985, lng: -97.337097 },
    { lat: 18.699088, lng: -97.324609 },
    { lat: 18.714586, lng: -97.308753 },
    { lat: 18.720767, lng: -97.293813 },
    { lat: 18.730939, lng: -97.268944 },
    { lat: 18.741892, lng: -97.246276 },
    { lat: 18.762047, lng: -97.224344 },
    { lat: 18.778837, lng: -97.202446 },
    { lat: 18.79862119, lng: -97.17989903 },
    { lat: 18.803502, lng: -97.175365 },
    { lat: 18.808911, lng: -97.16905 },
    { lat: 18.816843, lng: -97.152219 },
    { lat: 18.827468, lng: -97.145882 },
    { lat: 18.830921, lng: -97.13614 },
    { lat: 18.827264, lng: -97.126487 },
    { lat: 18.822767, lng: -97.110949 },
    { lat: 18.825874, lng: -97.102954 },
    { lat: 18.82893, lng: -97.093396 },
    { lat: 18.813263, lng: -97.094276 },
    { lat: 18.807286, lng: -97.101901 },
    { lat: 18.793217, lng: -97.103517 },
    { lat: 18.780926, lng: -97.088997 },
    { lat: 18.765856, lng: -97.086134 },
    { lat: 18.758946, lng: -97.092379 },
    { lat: 18.736733, lng: -97.095092 },
    { lat: 18.72853, lng: -97.08624 },
    { lat: 18.697098, lng: -97.095168 },
    { lat: 18.673585, lng: -97.058366 },
    { lat: 18.661043, lng: -96.996691 },
    { lat: 18.66517, lng: -96.992051 },
    { lat: 18.671573, lng: -96.996845 },
    { lat: 18.68366, lng: -97.02005 },
    { lat: 18.75259, lng: -97.06949 },
    { lat: 18.7803, lng: -97.064 },
    { lat: 18.81272, lng: -97.05747 },
    { lat: 18.831211, lng: -97.082706 },
    { lat: 18.84247, lng: -97.07222 },
    { lat: 18.846192, lng: -97.039137 },
    { lat: 18.819046, lng: -97.020659 },
    { lat: 18.803823, lng: -97.028871 },
    { lat: 18.77374, lng: -97.0154 },
    { lat: 18.796427, lng: -96.985393 },
    { lat: 18.80359, lng: -96.96183 },
    { lat: 18.77952, lng: -96.94217 },
    { lat: 18.623619, lng: -96.78457 },
    { lat: 18.544994, lng: -96.74539 },
    { lat: 18.535075, lng: -96.735057 },
    { lat: 18.540189, lng: -96.684669 },
    { lat: 18.50822, lng: -96.61258 },
    { lat: 18.48988, lng: -96.549073 },
    { lat: 18.48682, lng: -96.471742 },
    { lat: 18.511396, lng: -96.412267 },
    { lat: 18.66637, lng: -96.43131 },
    { lat: 18.76327, lng: -96.51783 },
    { lat: 18.76392, lng: -96.57345 },
    { lat: 18.78992, lng: -96.68743 },
    { lat: 18.89585, lng: -96.75747 },
    { lat: 18.97119, lng: -96.69292 },
    { lat: 18.99002, lng: -96.7403 },
    { lat: 18.94908, lng: -96.85414 },
    { lat: 18.97356, lng: -97.00129 },
    { lat: 19.00054, lng: -97.0031 },
    { lat: 19.01901, lng: -97.00335 },
    { lat: 19.01771, lng: -96.93743 },
    { lat: 19.0202, lng: -96.91828 },
    { lat: 19.0203, lng: -96.89898 },
    { lat: 19.05665, lng: -96.88387 },
    { lat: 19.060588, lng: -96.854144 },
    { lat: 19.043039, lng: -96.824516 },
    { lat: 19.046803, lng: -96.809063 },
    { lat: 19.0612, lng: -96.80765 },
    { lat: 19.098657, lng: -96.807668 },
    { lat: 19.136183, lng: -96.73858 },
    { lat: 19.143428, lng: -96.734932 },
    { lat: 19.166872, lng: -96.779961 },
    { lat: 19.172016, lng: -96.818527 },
    { lat: 19.233883, lng: -96.834817 },
    { lat: 19.234812, lng: -96.851762 },
    { lat: 19.225083, lng: -96.864936 },
    { lat: 19.216954, lng: -96.903676 },
    { lat: 19.224221, lng: -96.979853 },
    { lat: 19.318755, lng: -96.889653 },
    { lat: 19.32131, lng: -96.90826 },
    { lat: 19.32772, lng: -96.93863 },
    { lat: 19.21364, lng: -97.02377 },
    { lat: 19.151784, lng: -97.073256 },
    { lat: 19.108598, lng: -97.141928 },
    { lat: 19.0473, lng: -97.151553 },
    { lat: 19.008369, lng: -97.231017 },
    { lat: 19.063876, lng: -97.318006 },
    { lat: 19.294754, lng: -97.404523 },
    { lat: 19.36214, lng: -97.51988 },
    { lat: 19.240416, lng: -97.648938 },
    { lat: 19.162622, lng: -97.752169 },
    { lat: 18.949144, lng: -97.892713 },
    { lat: 18.873793, lng: -97.905759 },
    { lat: 18.65308, lng: -97.995729 },
    { lat: 18.550909, lng: -98.063707 },
    { lat: 18.391999, lng: -98.061647 },
    { lat: 18.416719, lng: -97.811269 },
    { lat: 18.553795, lng: -97.788144 },
    { lat: 18.593801, lng: -97.700267 },
    { lat: 18.427493, lng: -97.556816 },
    { lat: 18.19461, lng: -97.745782 },
    { lat: 18.135365, lng: -97.640596 },
    { lat: 18.213432, lng: -97.499327 },
    { lat: 18.309937, lng: -97.397703 },
    { lat: 18.244496, lng: -97.339798 },
    { lat: 18.213843, lng: -97.242981 },
  ];

  const friconArea = new window.google.maps.Polygon({
    paths: polygonCoords,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  });

  friconArea.setMap(terMap);
};


const TERRITORY = () => {
    
  useEffect(() => {
    initMap();
  }, []);

  return (
    <div className="territory_container">
      <div className="territory-content">
        <div className="territory-grid">
          <div className='tg1'>
            <div className='section-info'>
                <p className='title' translate="no">Territorio:</p>
                <p translate="no">Territorio atendidos por Fricongelados.</p>
                <ul>
                    <li translate="no">Tehuacan.</li>
                    <li translate="no">Cd. Mendoza.</li>
                    <li translate="no">Nogales.</li>
                    <li translate="no">Rio Blanco.</li>
                    <li translate="no">Orizaba.</li>
                    <li translate="no">Ixtaczoquitlán.</li>
                    <li translate="no" >Fortín de las flores.</li>
                    <li translate="no">Córdoba.</li>
                    <li translate="no">Coscomatepec.</li>
                    <li translate="no">Huatusco.</li>
                </ul>

            </div>
          </div>
          <div className="tg2">
            <div id="terMap" style={{ height: '500px', width: '100%' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TERRITORY;
