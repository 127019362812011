import React from 'react';
import "./mision.css";
import paleta from "../../images/paleta.png";

export const MISION = () => {
  return (
    <div className='mision-container' id='mision-container'>
        <div className='mision-content'>
            <div className='grid-mision'>
                <div className='mision-desc'>
                    <h2>MISIÓN</h2>
                    <p>
                        Ofrecer un servicio de excelencia en la distribución, a un bajo costo 
                        de operación, utilizando la más alta tecnología de información, así 
                        como la infraestructura que asegura una inmejorable oportunidad de negocio 
                        a nuestros clientes. Dirigiendo nuestras acciones a la sustentabilidad 
                        creando conciencia ambiental.
                    </p>
                </div>
                <div className='mision-image'>
                    <img src={paleta} alt='paleta'></img>
                </div>
            </div>
        </div>
    </div>
  )
}
