import React from 'react'
import { NAVAR } from './navbar/navbar'
import { SLIDE } from './slide/slide'
import { SECTION } from './section/section'
import { VALUES } from './values/values'
import { MISION } from './mision/mision'
import { VISION } from './vision/vision'
import { CONTACT } from './contact/contact'
import { FOOTER } from './footer/footer'

export const INDEX = () => {

  return (
    <div>
        <NAVAR></NAVAR>
        <SLIDE></SLIDE>
        <SECTION></SECTION>
        <MISION></MISION>
        <VISION></VISION>
        <VALUES></VALUES>
        <CONTACT></CONTACT>
        <FOOTER></FOOTER>
    </div>
  )
}
