import React, { useState } from 'react';
import "./contact.css";
import { post_order } from '../../services/services';

export const CONTACT = () => {

    const [name, setname] = useState("");
    const [tel, settel]=useState("");
    const [email, setemail]=useState("");
    const [msj, setmsj]=useState("");

    const sendemail = async() =>{

        if(email === "" || msj === "" || name === "" || tel === ""){
            alert("Valores faltantes en el formulario");
            return null;
        }
        var data = {
            email: email,
            id_freezer: "",
            message: msj,
            name: name,
            order:"",
            phone: tel,
            type: "contactar"
        }

        try{
            const d = await post_order(data);
            console.log(d);
            setname("");
            settel("");
            setemail("");
            setmsj("");
            alert("Correo enviado con éxito");
        }catch(err){
            console.log(err);
        }

    }


  return (
    <div className='contact-container' id='contact-container'>
        <div className='contact-content'>
            <div className='contact-grid'>
                <div className='contg1'>
                    <div className='address'>
                        <div className='hours'>
                            <h2>Horario</h2>
                            <p>Lunes a Sabado: 8am - 6pm</p>
                        </div>
                        <div className='mail'>
                            <h2>Correo</h2>
                            <p>atencion.clientes@fricongelados.com</p>
                        </div>
                        <div className='city'>
                            <h2>Dirección</h2>
                            <p>Orizaba, Veracruz.</p>
                        </div>
                        
                    </div>
                    <div className='gmaps'>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d280.64053609997524!2d-97.07327076434161!3d18.85005041780339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c4fd47c5b28919%3A0x862f0b83fdd7bb5e!2sFricongelados%20Citlaltepetl!5e0!3m2!1ses-419!2smx!4v1720213610305!5m2!1ses-419!2smx" 
                            allowfullscreen=""
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
                <div className='contg2'>
                    <div className='forms'>
                    <h2>CONTACTO</h2>
                    <input 
                        placeholder='Nombre Completo'
                        value={name}
                        onChange={(e)=>setname(e.target.value)}
                    ></input>
                    <input 
                        placeholder='Número Teléfono'
                        value={tel}
                        onChange={(e)=>settel(e.target.value)}    
                    ></input>
                    <input 
                        placeholder='Correo Electrónico'
                        value={email}
                        onChange={(e)=>setemail(e.target.value)}
                    ></input>
                    <input 
                        placeholder='Mensaje'
                        value={msj}
                        onChange={(e)=>setmsj(e.target.value)}
                    ></input>
                    <div className='submit'>
                        <button onClick={()=>sendemail()}>ENVIAR</button>
                    </div>

                   
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
  )
}
