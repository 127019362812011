import React from 'react';
import "./vision.css";
import imgmagnum from "../../images/e1b17162777475dad99d27186fa2453d.png";

export const VISION = () => {
  return (
    <div className='vision-container' id='vision-container'>
        <div className='cap-black'></div>
        <div className='vision-content'>
            <div className='vision-grid'>
                <div className='visg1'>
                    <img src={imgmagnum} alt='magnum'></img>
                </div>
                <div className='visg2'>
                    <div className='description'>
                        <h2>VISIÓN</h2>
                        <p>Ser una empresa líder en la distribución de productos de alta calidad, 
                            con el mejor servicio en nuestra zona de influencia, ofreciendo las
                             mejores oportunidades de negocio a nuestros clientes y colaboradores, 
                             con el fin de posicionarnos como una empresa eficiente y rentable.</p>
                    </div>
                </div>
            </div>
        </div>  

    </div>
  )
}
