import axios from "axios";
import awsconfig from '../aws-exports';
import Auth from '@aws-amplify/auth';
import {Amplify} from 'aws-amplify';
import AWS from 'aws-sdk';
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { Sha256 } from "@aws-crypto/sha256-js";

  
export const post_order = async (data) => {
    const url = `https://454ayndsx1.execute-api.us-east-1.amazonaws.com/Productive/concatct`;
    try {
      const response = await axios.post(url, data, {
        headers: {
         //   Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
};

export const get_jobs = async () => {

  const url = 'https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/web';

  try{
        // const provider = {
    //   accessKeyId: accessKeyId,
    //   secretAccessKey: secretAccessKey,
    //   sessionToken: sessionToken,
    // };
    // const signer = new SignatureV4({
    //   credentials: provider,
    //   sha256: Sha256,
    //   region: 'us-east-1',
    //   service: 'execute-api',
    // });
    // const request = {
    //   method: 'GET',
    //   url: url,
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    // };
    // const signedRequest = await signer.sign({
    //   method: request.method,
    //   hostname: 'hq8d2hivua.execute-api.us-east-1.amazonaws.com',
    //   path: '/development/web',
    //   headers: request.headers,
    // });
    // const response = await axios.get(url, {
    //   headers: signedRequest.headers,
    // });   
    console.log(response);
    const response = await axios.get(url,{
       headers: {
       //Authorization: credentials?.sessionToken,
       //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
         "Content-Type": "application/json",
       },
    }); 
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const post_application = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/application`;
  try {
    const response = await axios.post(url, data,{
      headers: {
      //Authorization: credentials?.sessionToken,
      //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const upload_file = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/document`;
  try {
    const response = await axios.post(url, data,{
      headers: {
      //   Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generate_veritication_code = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/application/send`;
  try {
    const response = await axios.post(url, data,{
      headers: {
      //   Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verification_code = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/application/check`;
  try {
    const response = await axios.post(url, data,{
      headers: {
      //   Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


