import './App.css';
import { INDEX } from './pages';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { LABOR_EXCHANGE } from './pages/jobs/jobs';
import { DESCRIPTION_JOB } from './pages/jobs/tools/description_job';

function App() {
  return (
    <Router>
    <Switch> 
        <Route path={"/"} exact component={INDEX}></Route>
        <Route path={"/labor_exchange"} exact component={LABOR_EXCHANGE}></Route>
        <Route path={"/labor_exchange/description"} exact component={DESCRIPTION_JOB}></Route>
        <Redirect to="/"></Redirect>
    </Switch>
</Router>
  );
}

export default App;
